import React from 'react'
import { Link } from 'gatsby'

// Images
import ArrowSecondary from 'img/arrow_secondary.inline.svg'
import Facebook from 'img/SA-facebook.inline.svg'
import LinkedIn from 'img/SA-Linkedin.inline.svg'
import Twitter from 'img/SA-x.inline.svg'
import Mail from 'img/SA-mail.inline.svg'

// ShareButtons
import {
  ShareButtonFacebook,
  ShareButtonTwitter,
  ShareButtonLinkedIn,
  ShareButtonEmail,
} from 'components/elements/ShareButton'

// Third Party
import styled from 'styled-components'

const StyledShare = styled.div`
  color: ${(props) => props.theme.color.text.dark};

  a:hover {
    color: ${(props) => props.theme.color.text.contrast};
    text-decoration: underline;
  }
`

interface ShareProps {
  location: any
  returnUrl: string
  title: string
}

const Share: React.FC<ShareProps> = ({ title, returnUrl, location }) => (
  <StyledShare>
    <div className="pb-3">{title}</div>
    <div>
      <ShareButtonLinkedIn
        className="px-0 mr-2"
        title={title}
        location={location.href}
      >
        <LinkedIn />
      </ShareButtonLinkedIn>
      <ShareButtonFacebook className="px-0 mr-2" location={location.href}>
        <Facebook />
      </ShareButtonFacebook>
      <ShareButtonTwitter
        className="px-0 mr-2"
        title={title}
        location={location.href}
      >
        <Twitter />
      </ShareButtonTwitter>
      <ShareButtonEmail
        className="px-0 mr-2"
        title={title}
        location={location.href}
      >
        <Mail />
      </ShareButtonEmail>
    </div>

    <Link
      className="d-flex pt-5 align-items-center text-contrast"
      to={returnUrl}
    >
      <ArrowSecondary />
      <div className="ml-2">Back to overview</div>
    </Link>
  </StyledShare>
)

export default Share
